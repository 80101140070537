<template>
    <div class="protocol">
        <h1> 信息安全条款</h1>
        <p>1，本人授权保险公司，除法律另有规定之外，将本人提供给保险公司的信息、享受保险公司服务产生的信息（包括本〔单证〕签署之前提供和产生 的信息）以及保险公司根据本条约定查询、收集的信息，用于保险公司及其因服务必要委托的合作伙伴为本人提供服务、推荐产品、开展市场调查 与信息数据分析。</p>
        <p>2，本人授权保险公司，除法律另有规定之外，基于为本人提供更优质服务和产品的目的，向保险公司因服务必要开展合作的伙伴提供、查询、收集 本人的信息。为确保本人信息的安全，保险公司及其合作伙伴对上述信息负有保密义务，并采取各种措施保证信息安全。</p>
        <p>3、本条款自本〔单证〕签署时生效，具有独立法律效力 , 不受合同成立与否及效力状态变化的影响。</p>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
     .protocol{
        padding: 0 24px;
        color: #666;
    }
    h1{
        text-align: center;
        padding: 30px 0px;
    }
    h2{
        font-size: 30px;
    }
    p{
        font-size: 26px;
        margin:10px 0 20px 0;
    }
     
</style>